import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { serviceAPI } from '../constant/service-api';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NecCloudService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * get list of cameras by NEC Cloud userId
   * @param param contain userId which belong to NEC cloud system
   */
  getCamerasByUserId(param: { user_id: string }): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.nec.getCamerasByUserId, param);
    return this.http.get(api);
  }

  /**
   * get list of cameras by NEC 2 Cloud userId
   * @param param contain userId which belong to NEC 2 cloud system
   */
  getCamerasNEC2ByUserId(param: { user_id: string }): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.nec.getCamerasNEC2ByUserId, param);
    return this.http.get(api);
  }

}
