import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { storageManager } from '../constant/storage-manager';

@Injectable({ providedIn: 'root' })
export class UserConfigResolver implements Resolve<any> {
  constructor() {}
  // Identify if access Customer or Admin Page with NEC rights
  resolve(): Observable<boolean> {
    return new Observable(observer => {
      const storageUserInfo = localStorage.getItem(storageManager.USER_INFO);
      if (storageUserInfo) {
        const userInfo = JSON.parse(storageUserInfo);
        observer.next(userInfo.config ? userInfo.config : {});
      } else {
        observer.next(false);
      }
      observer.complete();
    });
  }
}
