import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { serviceAPI } from '../constant/service-api';
import { NECCSVA_CAM } from '../constant/camera';

@Injectable({ providedIn: 'root' })
export class ImageService {

  constructor(private http: HttpClient, private commonService: CommonService) { }

  getShelfConfig(param: { shelfId: string; cameraId: string }): Observable<any> {
    // const index = Math.floor(Math.random() * 6);
    // return of(imgConfig[0]).pipe(delay(500));
    const api = this.commonService.buildAPIUrl(serviceAPI.image.getShelfConfig, param);
    return this.http.get(api);
  }

  getImageByCamera(param: { cameraId: string; date: string; hour: string; minute: string, s3dir: string, nec_cam_folder: string }): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.camera.getImageByCamera, param);
    return this.http.get(api);
  }

  getImageByCameraNEC2(param: { user_id: string; cameraId: string; from: any; to: any; bucket: any; env: any; }): Observable<any> {
    // const index = Math.floor(Math.random() * 6);
    // return of(imgConfig[0]).pipe(delay(500));
    const api = this.commonService.buildAPIUrl(serviceAPI.image.getImageByNEC2, param);
    return this.http.get(api);
  }

  // getImageByBaseIdNEC2(param: { user_id: string; cameraId: string; base_id: String }): Observable<any> {
  //   const api = this.commonService.buildAPIUrl(serviceAPI.image.getImageByNEC2, { ...param, bucket: NECCSVA_CAM.necssva_cam, env: NECCSVA_CAM.env });
  //   return this.http.get(api);
  // }

  cropImage(body): Observable<any> {
    const api = this.commonService.buildAPIUrl(serviceAPI.image.cropImage);

    const httpOptions = {
      headers: new HttpHeaders({
        'Access-key': 'hwowW9nHLN',
      }),
    };
    return this.http.post(api, body, httpOptions);
  }

  /**
 * Method handler for getting the binary data from server
 * @param key - string
 * @param type - cameraCloud || ''
 * @return http get client
 */
  getRawImage(params: { key: string, type?: string }): Observable<any> {
    const headers = new HttpHeaders({
      accept: '*/*',
      'content-type': 'application/xml',
    });
    const api = this.commonService.buildApiShelfApigw(serviceAPI.image.getBinaryFile, params);
    return this.http.get(api, {
      headers,
      responseType: 'text'
    });
  }
}
