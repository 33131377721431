import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable} from 'rxjs';
import { STORE_ADMIN} from '../constant/roles';

@Injectable({ providedIn: 'root' })
export class StoreAdminResolver implements Resolve<any> {
  constructor() {}
  // Identify if access Customer or Admin Page with administrative rights
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable(observer => {
      const type = localStorage.getItem('role');
      observer.next(type === STORE_ADMIN);
      observer.complete();
    });
  }
}
