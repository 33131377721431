import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../../core/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class EditUserResolverService implements Resolve<any> {
  type: any;
  constructor(private userService: UserService) {}

  resolve(router: ActivatedRouteSnapshot): Observable<any> {
    const userId = router.params.id;
    const params = {
      userId: userId,
    };

    return this.userService.getDetailUser(params).pipe(
      catchError((_) => {
        return of([]);
      })
    );
  }
}
